import './App.css';
import Contractconnect from './Components/Contractconnect';
import ConnectWallet from './Components/ConnectWallet';
import Navbar from './Components/Navbar';
import Home from './Components/Home';
import { BrowserRouter as Router,Routes, Route } from 'react-router-dom';


function App() {
    return (
      
      <div className="App">
        <Home />
        <Router>
        <Navbar/>
      <Routes>
        <Route path='/connect' element={<ConnectWallet />} />
        <Route path='/game' element={<Contractconnect />} />
        <Route path='*' element={<ConnectWallet />} />
      </Routes>
        </Router>
      </div>

       
    );
}

export default App;
