

import React from 'react';
import { NavLink } from 'react-router-dom';

function Navbar() {
  return (
    <div>
      <ul className="nav nav-pills">
        <li className="nav-item">
          <NavLink className="nav-link" to= '/connect'>
            Connect
          </NavLink>
        </li>
        <li className="nav-item">
          <NavLink className="nav-link" to= '/game'>
          game
          </NavLink>
        </li>
      </ul>

     </div>
  );
}

export default Navbar;