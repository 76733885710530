import React from 'react';

function test() {
  return (
    <div>
        <p className="p-3 text-primary-emphasis bg-primary-subtle border border-primary-subtle rounded-3">
            Welcome to Etherium based Web 3 Lottery Game
          </p>
     </div>
  );
}

export default test;