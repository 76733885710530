import React, { useState } from 'react';
import Web3 from 'web3';

function MetamaskConnector() {
  const [web3, setWeb3] = useState(null);
  const [connStatus, setConnStatus] = useState(null);
  const [balStatus, setBalStatus] = useState(null);
  const [sendEthstatus, setsendEthstatus] = useState(false);
  const [amountosend, setAmountosend] = useState(0);
  const [balance, setBalance] = useState(0);
  const [addrsstosend, setAddrsstosend] = useState('');


  const connectToMetamask = async () => {
    if (typeof window.ethereum !== 'undefined') {
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        const accounts = await web3Instance.eth.getAccounts();
        setConnStatus(`Connected to Metamask with address: ${accounts[0]}`);
      } catch (err) {
        console.error(err);
        setConnStatus('Error while Connecting');
      }
    } else {
      console.error('Metamask not detected');
      setConnStatus('Metamask not detected');
    }
  };

  const FetchWalltetBalance = async () => {

    if (typeof window.ethereum !== 'undefined') {
      try {
        console.log('Fetching Balance start');
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
          params: [{ eth_accounts: {} }],
        });


        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const web3Instance = new Web3(window.ethereum);
        setWeb3(web3Instance);
        const balanceEth = await web3Instance.eth.getBalance(accounts[0]);
        const weiAmount = web3.utils.fromWei(balanceEth.toString());
        setBalance(weiAmount);
        setBalStatus(`You have: ${weiAmount} wei`);
      } catch (err) {
        console.error(err);
        setConnStatus('Error while Connecting');
      }
    } else {
      console.error('Metamask not detected');
      setConnStatus('Metamask not detected');
    }
  };

  return (
    <div>
      <button type="button" className="btn btn-outline-info" onClick={()=>connectToMetamask() }>Connect to Metamask</button><br/>
      {(
        <p className="badge text-bg-primary">{connStatus}</p>
      )}
      <br/><br/>

<button type="button" className="btn btn-outline-info" onClick={()=>FetchWalltetBalance() }>Check your Balance</button><br/>
      {(
        <p className="badge text-bg-primary">{balStatus}</p>
      )}<br/><br/>

{/* <button type="button" className="btn btn-outline-info" onClick={()=>setsendEthstatus(true) }>Send Ether</button><br/>
{sendEthstatus &&
<div>
  <br/>
  <label>Enter Amount</label><br/>
  <input type="number" step="0.001" value={amountosend} max={balance} onChange={e => setAmountosend(e.target.value)} />
  <br/>
  <label>Enter Address</label><br/>
  <input type="text" value={addrsstosend} onChange={e => setAddrsstosend(e.target.value)} />
  <br/>
  <label>Enter Address</label><br/>
  <input type="text" value={addrsstosend} onChange={e => setAddrsstosend(e.target.value)} />

  </div>

} */}

    </div>
  );
      
}

export default MetamaskConnector;
